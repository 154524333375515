export const educationData = [
    {
        id: 1,
        institution: 'Lycée Galilée Gennevilliers',
        course: 'Diplôme Baccalauréat',
        startYear: '2018',
        endYear: '2021'
    },
    {
        id: 2,
        institution: 'Université Sorbonne Paris Nord',
        course: 'Bachelor Universitaire Technologie - Informatique',
        startYear: '2021',
        endYear: 'Présent'
    },

]